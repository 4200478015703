import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Login from '../../common/Login';
import styles from '../Steps/style.module.scss';
import emailStyles from './style.module.scss';
import Button from '../../common/Button';
import setDataLayer from '../../../helpers/DataLayer';

import getMageData, { getStoreData } from '../../../helpers/MageStorage';

const Email = (props) => {
  const {
    currentStep,
    steps,
    emailAddress,
    isValid,
    setCustomerEmail,
    setStep,
    setStepValid,
    setCheckingOut,
    customerLoginEmailAddress,
    customerLogin,
    setStoreLoginStep,
    loginStatus,
    loginJwt,
    loginStep,
    loader,
    cartData,
    customerRegistered,
    shippingAddress,
    loadShippingMethods,
  } = props;

  const {
    success: loggedIn,
  } = loginStatus;

  const { order } = steps;
  const thisStepIndex = order.findIndex((step) => step.name === 'email');
  const isReady = (currentStep === thisStepIndex);


  const [showLogin, setShowLogin] = useState(false);

  // adding start information to the datalayer and checking the first step
  useEffect(() => {
    if (isReady) {
      const option = !loggedIn ? 'guest' : 'known';
      setDataLayer(cartData, currentStep, option);
    }
  }, [currentStep, loggedIn]);

  const getShippingMethods = async () => {
    if (!shippingAddress.postcode) {
      const deliveryData = getMageData();
      if (deliveryData.hasOwnProperty('deliveryPostCode')) {
        shippingAddress.postcode = deliveryData.deliveryPostCode;
      }
    }
    await loadShippingMethods(shippingAddress);
  };

  // checking the click on the button and moving to the second step of checkout
  const toSecondStep = () => {
    getShippingMethods();
    setStep(currentStep + 1);
  };

  return (
    <div className={styles.step__inner}>
      <div className={emailStyles.email__wrapper}>
        {!isValid && (
          <div className={styles.step__edit}>
            {!loggedIn && (
              <>
                <Login
                  emailAddress={emailAddress}
                  submitEmail={customerLoginEmailAddress}
                  submitPassword={customerLogin}
                  setStoreLoginStep={setStoreLoginStep}
                  loginStatus={loginStatus}
                  loginJwt={loginJwt}
                  loginStep={loginStep}
                  loader={loader}
                  close={setShowLogin}
                  showLogin={showLogin}
                />
                {!showLogin && (
                  <Formik
                    initialValues={{ email: emailAddress }}
                    validate={(values) => {
                      const errors = {};
                      if (!values.email) {
                        errors.email = 'Required';
                      } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                      ) {
                        errors.email = 'Invalid email address';
                      }
                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {
                        setCustomerEmail(values.email);
                        setSubmitting(false);
                      }, 10);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className={emailStyles.email}>
                          <label htmlFor="email">
                            Enter your email address
                          </label>
                          <input
                            className={emailStyles.emailInput}
                            type="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                          <button type="submit" disabled={isSubmitting} className={emailStyles.emailSubmit}>
                            Proceed
                          </button>
                        </div>
                        <div className="Alert AlertError">
                          {errors.email && touched.email && errors.email}
                        </div>
                      </form>
                    )}
                  </Formik>
                )}
              </>
            )}
          </div>
        )}

        {isValid && (
          <div className={emailStyles.customerEmail}>
            <h5>{emailAddress}</h5>

            {!loggedIn && (
              <div className={styles.step__edit}>
                <button className="linkAstext" type="button" onClick={() => setStepValid('email', false)}>
                  edit
                </button>
              </div>
            )}
          </div>
        )}

        <div className={styles.step__nav}>
          {isValid && (
              <div className={emailStyles.buttonWrapper}>
                  {customerRegistered && !loggedIn && (
                    <>
                      <Button
                        trigger={() => {
                          setStepValid('email', false);
                          setShowLogin(true);
                          setStoreLoginStep(1);
                        }}
                        label="Sign in"
                        buttonStyle="primary proceed"
                      />
                      <span>&nbsp;Or&nbsp;</span>
                    </>
                  )}

                <Button
                  trigger={() => toSecondStep()}
                  label={loggedIn ? 'Proceed to shipping' : 'Proceed as guest'}
                  buttonStyle="primary proceed"
                />
                      {/*{!customerRegistered && !loggedIn && (*/}
                      {/*    <>*/}
                      {/*        <p><small>You will be able to create an account at the end of the checkout</small></p>*/}
                      {/*    </>*/}
                      {/*)}*/}

              </div>
          )}
        </div>
      </div>
    </div>
  );
};

Email.propTypes = {
  currentStep: PropTypes.number,
  isValid: PropTypes.bool,
  emailAddress: PropTypes.string,
  setStep: PropTypes.func,
  setCustomerEmail: PropTypes.func,
  setStepValid: PropTypes.func,
  setCheckingOut: PropTypes.func,
  customerLoginEmailAddress: PropTypes.func,
  customerLogin: PropTypes.func,
  setStoreLoginStep: PropTypes.func,
  loginStatus: PropTypes.shape({
    success: PropTypes.bool,
    message: PropTypes.string,
  }),
  loginJwt: PropTypes.string,
  loginStep: PropTypes.number,
  shippingAddress: PropTypes.shape({}),
  cartData: PropTypes.shape({
    cart_type: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  customerRegistered: PropTypes.bool,
  loadShippingMethods: PropTypes.func,
};

Email.defaultProps = {
  currentStep: 0,
  isValid: false,
  emailAddress: '',
  shippingAddress: {},
  setStep: () => {},
  setCustomerEmail: () => {},
  setStepValid: () => {},
  setCheckingOut: () => {},
  customerLoginEmailAddress: () => {},
  customerLogin: () => {},
  setStoreLoginStep: () => {},
  loginStatus: {
    success: false,
    message: '',
  },
  loginJwt: '',
  loginStep: 1,
  cartData: {
    items: [],
  },
  customerRegistered: false,
  loadShippingMethods: () => {},
};

export default Email;
