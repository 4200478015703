/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
} from 'formik';
const CheckoutModal = React.lazy(() => import('../../common/CheckoutModal'));
import formStyles from '../../../styles/input.module.scss';
import Button from '../Button';
import styles from './style.module.scss';


const Login = (props) => {
  const {
    emailAddress,
    submitEmail,
    submitPassword,
    setStoreLoginStep,
    loginStatus,
    loginJwt,
    loginStep,
    loader,
    close,
    showLogin,
  } = props;

  if (loginStatus.success) {
    close();
  }

  const createAccount = () => {
    window.location.pathname = '/customer/account/create/';
  };

  const forgotPassword = () => {
    window.location.pathname = '/customer/account/forgotpassword/';
  };

  const LoginModal = () => (
    <div className={styles.loginWrapper}>

  {loginStep !== 2 && (
       <Formik
        initialValues={{ email: emailAddress, password: ''}}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = 'Required';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            submitEmail(values.email, values.password);
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className={formStyles.field}>
              <label className={styles.visuallyHide} htmlFor="email">
                Email Address
              </label>
              <div className={`${formStyles.control} ${formStyles.controlLimitedWidth}`}>
                <Field
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  disabled={loader}
                />
              </div>
              {errors.email && touched.email ? (
                <div>{errors.email}</div>
              ) : null}
            </div>
            <div>
              <label className={styles.visuallyHide} htmlFor="password">
                Password
              </label>
              <div className={`${formStyles.control} ${formStyles.controlLimitedWidth}`}>
                <Field
                  type="password"
                  name="password"
                  placeholder="Your Password"
                  className={styles.visuallyHide}
                />
              </div>
            </div>

            {loginStatus.message && (
              <div className={styles.loginErrorMessage}>
                {loginStatus.message}
              </div>
            )}

              <div>
              <button label="Next" className={loader ? styles.loginActionsSubmiting : styles.loginActionsSubmit}
              type="submit" disabled={loader}>
                    <span className={styles.loginActionText}>Next</span>
                    <span className={styles.loginActionIcon}>
                        <svg height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg"><path d="m30 3.8c8.2842712 0 15 6.8052718 15 15.2s-6.7157288 15.2-15 15.2c-6.386315 0-12.0084647-4.0815997-14.1261884-10.0761704-.1396059-.395178.0633609-.830215.4533392-.9716824s.8192911.0642057.9588971.4593837c1.9058367 5.3947892 6.9661022 9.0684691 12.7139521 9.0684691 7.4558441 0 13.5-6.1247446 13.5-13.68s-6.0441559-13.68-13.5-13.68c-7.083186 0-12.8922751 5.5277912-13.4552799 12.5584265l2.4250299-2.4558415c.292875-.29678.767625-.29678 1.0605 0s.292875.77786 0 1.07464l-3.75 3.8c-.1065.10792-.2370496.1765964-.3741582.2060291l-.1037879.0147164h-.1046078c-.1741186-.0122637-.3448211-.0858455-.4779461-.2207455l-3.75-3.8c-.146625-.14858-.21975-.34276-.21975-.53732s.073125-.38874.21975-.53732c.292875-.29678.767625-.29678 1.0605 0l2.5043359 2.5370217c.5276214-7.9094723 7.0262231-14.1596067 14.9654141-14.1596067zm0 9.880076c2.895 0 5.25 2.3864 5.25 5.32s-2.355 5.32-5.25 5.32-5.25-2.3864-5.25-5.32 2.355-5.32 5.25-5.32zm0 1.52c-2.06775 0-3.75 1.70468-3.75 3.8s1.68225 3.8 3.75 3.8 3.75-1.70468 3.75-3.8-1.68225-3.8-3.75-3.8z" fill="#fff" fill-rule="evenodd" transform="matrix(.58778525 -.80901699 .80901699 .58778525 -17.00488 29.10259)"/></svg>
                    </span>
               </button>
              </div>
          </Form>
        )}
      </Formik>
    )}
      
    {loginStep === 2 && (
      <Formik
        initialValues={{password: ''}}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            submitPassword(loginJwt, emailAddress, values.password);
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
             <div className={formStyles.field}>
               <div className={styles.loginHeader}>
                  <h5>{emailAddress}</h5>
                  <Button label="Change" buttonStyle="fullWidth link" trigger={() => { setStoreLoginStep(1) }} />
               </div>
            </div>
            <div className={formStyles.field}>
              <label className={styles.visuallyHide} htmlFor="password">
                Password
              </label>
              <div className={`${formStyles.control} ${formStyles.controlLimitedWidth}`}>
                <Field
                  type="password"
                  name="password"
                  placeholder="Your Password"
                  disabled={loader}
                />
              </div>
              {errors.password && touched.password ? (
                <div className={styles.loginErrorMessage}>{errors.password}</div>
              ) : null}
            </div>

            <div>
            <button label="Sign In" className={loader ? styles.loginActionsSubmiting : styles.loginActionsSubmit}
                    type="submit" disabled={loader}>
                    <span className={styles.loginActionText}>Sign In</span>
                    <span className={styles.loginActionIcon}>
                        <svg height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg"><path d="m30 3.8c8.2842712 0 15 6.8052718 15 15.2s-6.7157288 15.2-15 15.2c-6.386315 0-12.0084647-4.0815997-14.1261884-10.0761704-.1396059-.395178.0633609-.830215.4533392-.9716824s.8192911.0642057.9588971.4593837c1.9058367 5.3947892 6.9661022 9.0684691 12.7139521 9.0684691 7.4558441 0 13.5-6.1247446 13.5-13.68s-6.0441559-13.68-13.5-13.68c-7.083186 0-12.8922751 5.5277912-13.4552799 12.5584265l2.4250299-2.4558415c.292875-.29678.767625-.29678 1.0605 0s.292875.77786 0 1.07464l-3.75 3.8c-.1065.10792-.2370496.1765964-.3741582.2060291l-.1037879.0147164h-.1046078c-.1741186-.0122637-.3448211-.0858455-.4779461-.2207455l-3.75-3.8c-.146625-.14858-.21975-.34276-.21975-.53732s.073125-.38874.21975-.53732c.292875-.29678.767625-.29678 1.0605 0l2.5043359 2.5370217c.5276214-7.9094723 7.0262231-14.1596067 14.9654141-14.1596067zm0 9.880076c2.895 0 5.25 2.3864 5.25 5.32s-2.355 5.32-5.25 5.32-5.25-2.3864-5.25-5.32 2.355-5.32 5.25-5.32zm0 1.52c-2.06775 0-3.75 1.70468-3.75 3.8s1.68225 3.8 3.75 3.8 3.75-1.70468 3.75-3.8-1.68225-3.8-3.75-3.8z" fill="#fff" fill-rule="evenodd" transform="matrix(.58778525 -.80901699 .80901699 .58778525 -17.00488 29.10259)"/></svg>
                    </span>
              </button>
            </div>
          </Form>
        )}
      </Formik>
      )}

      <div className={styles.loginActions}>
          <Button label="Forgot your password?" buttonStyle="fullWidth link" trigger={() => { forgotPassword(); }} />
          <Button label="Create account" buttonStyle="fullWidth link" trigger={() => { createAccount(); }} />
      </div>
    </div>
  );

  return (
    <CheckoutModal
      isOpen={showLogin}
      onRequestClose={() => { close(); }}
      contentLabel="Sign In"
      modalTitle="Sign In"
      modalContent=""
      exitModal={() => { close(); }}
      confirmAction={() => { confirmCheckoutCancellation(); }}
      large
    >
      <LoginModal />
    </CheckoutModal>
  );
};

Login.propTypes = {
  close: PropTypes.func,
  emailAddress: PropTypes.string,
  submitEmail: PropTypes.func,
  submitPassword: PropTypes.func,
  loginStatus: PropTypes.shape({
    success: PropTypes.bool,
    message: PropTypes.string,
  }),
  loginJwt: PropTypes.string,
  loginStep: PropTypes.number,
  showLogin: PropTypes.bool,
};

Login.defaultProps = {
  close: () => {},
  emailAddress: '',
  submitEmail: () => {},
  submitPassword: () => {},
  loginStatus: {
    success: false,
    message: '',
  },
  loginJwt: '',
  loginStep: 1,
  showLogin: false,
};

export default Login;
