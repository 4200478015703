import { connect } from 'react-redux';
import Email from '../../components/wrappers/Email';

import {
    setCustomerEmail,
    customerLogin,
    customerLoginEmailAddress,
    setStoreLoginStep,
    getShippingMethods
} from '../../store/actions/checkout';

const mapStateToProps = (state) => ({
  isRegistered: state.customer.isRegistered,
  emailAddress: state.customer.form.emailAddress,
  loginStatus: state.customer.loginStatus,
  loginJwt: state.customer.loginJwt,
  loginStep: state.customer.loginStep,
  loader: state.checkout.loader,
  cartData: state.checkout.cart,
  customerRegistered: state.customer.isRegistered,
  shippingAddress: state.customer.form.shippingAddress,
});

const mapDispatchToProps = (dispatch) => ({
  setCustomerEmail: (customerEmail) => dispatch(setCustomerEmail(customerEmail)),
  customerLoginEmailAddress: (
    customerEmail,
    customerPass,
  ) => dispatch(customerLoginEmailAddress(customerEmail, customerPass)),
  customerLogin: (
    customerToken,
    customerEmail,
    customerPass,
  ) => dispatch(customerLogin(customerToken, customerEmail, customerPass)),
  setStoreLoginStep: (step) => dispatch(setStoreLoginStep(step)),
  loadShippingMethods: (address) => dispatch(getShippingMethods(address)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Email);
